<template>
  <!-- menu-li end -->
  <div class="wrapper">
    <div>
      <!-- HeaderFlix end-->
      <div class="IndexBanner" style="height: 399px;">
        <div class="swiper-container swiper-container-horizontal">
          <div class="swiper-wrapper" style="transform: translate3d(-1920px, 0px, 0px); transition-duration: 0ms;">
            <div class="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active swiper-slide-prev"
              data-swiper-slide-index="0" style="height: 399px; width: 1920px;">
              <img src="./assets/tiya/d75c4415-9e90-4353-bdf7-396dc09ecbd4.png" style="height: 399px;">
            </div>
            <div class="swiper-slide swiper-slide-active swiper-slide-duplicate-next swiper-slide-duplicate-prev"
              data-swiper-slide-index="0" style="height: 399px; width: 1920px;">
              <img src="./assets/tiya/d75c4415-9e90-4353-bdf7-396dc09ecbd4.png" style="height: 399px;">
            </div>
            <div class="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active swiper-slide-next"
              data-swiper-slide-index="0" style="height: 399px; width: 1920px;">
              <img src="./assets/tiya/d75c4415-9e90-4353-bdf7-396dc09ecbd4.png" style="height: 399px;">
            </div>
          </div>
          <div class="IndexBanner_Layout">
            <h2 data-scroll-reveal="wait 0.1s, then enter left and move 40px over 1s" data-scroll-reveal-id="1"
              style="-webkit-transform: translatex(-40px);transform: translatex(-40px);opacity: 0;-webkit-transition: -webkit-transform 1s ease-in-out 0s,  opacity 1s ease-in-out 0.1s;transition: transform 1s ease-in-out 0s,  opacity 1s ease-in-out 0.1s;-webkit-perspective: 1000;-webkit-backface-visibility: hidden;"
              data-scroll-reveal-initialized="true">Hang Out With Friends</h2>
          </div>
        </div>
        <a href="javascript:void 0" class="ArrowImg"></a>
      </div>
      <!-- IndexBanner end -->
      <div id="page"></div>
      <!--    <div class="Vision">-->
      <!--        <div class="container">-->
      <!--            <h2 class="BaseH" data-scroll-reveal="wait 0.1s, then enter bottom and move 40px over 1s">-->
      <!--                VISION-->
      <!--            </h2>-->
      <!--            <div class="Vision_H" data-scroll-reveal="wait 0.12s, then enter bottom and move 40px over 1s">To be the world&rsquo;s largest audio platform</div>
<p data-scroll-reveal="wait 0.14s, then enter bottom and move 40px over 1s">We have created an audio ecosystem through innovative products and technology in hopes of bringing people closer together.</p>-->
      <!--        </div>-->
      <!--    </div>-->
      <div class="Globalization">
        <div class="container">
          <h2 class="BaseH" data-scroll-reveal="wait 0.1s, then enter bottom and move 40px over 1s"
            data-scroll-reveal-id="2"
            style="-webkit-transform: translatey(40px);transform: translatey(40px);opacity: 0;-webkit-transition: -webkit-transform 1s ease-in-out 0s,  opacity 1s ease-in-out 0.1s;transition: transform 1s ease-in-out 0s,  opacity 1s ease-in-out 0.1s;-webkit-perspective: 1000;-webkit-backface-visibility: hidden;"
            data-scroll-reveal-initialized="true">
            GLOBALIZATION</h2>
          <div class="Earth">
            <img src="./assets/tiya/earth.png" width="100%" class="Earth_Img">
            <div class="Earth_Ct">
              <div class="Earth_Dian active"></div>
              <div class="Earth_Line">
                <img src="./assets/tiya/line.png" width="100%" class="active">
                <div class="Earth_Bordert Base_Border rippletshow"></div>
              </div>
              <div class="Earth_Line1">
                <img src="./assets/tiya/line1.png" width="100%" class="active">
                <div class="Earth_Border1 Base_Border rippletshow"></div>
              </div>
              <div class="Earth_Line2">
                <img src="./assets/tiya/line2.png" width="100%" class="active">
                <div class="Earth_Border2 Base_Border rippletshow"></div>
              </div>
              <div class="Earth_Line3">
                <img src="./assets/tiya/line3.png" width="100%" class="active">
                <div class="Earth_Border3 Base_Border rippletshow"></div>
              </div>
              <div class="Earth_Line4">
                <img src="./assets/tiya/line4.png" width="100%" class="active">
                <div class="Earth_Border4 Base_Border rippletshow"></div>
              </div>
              <div class="Earth_Line5">
                <img src="./assets/tiya/line5.png" width="100%" class="active">
                <div class="Earth_Border5 Base_Border rippletshow"></div>
              </div>
              <div class="Earth_Line6">
                <img src="./assets/tiya/line6.png" width="100%" class="active">
                <div class="Earth_Border6 Base_Border rippletshow"></div>
              </div>
              <div class="Earth_Line7">
                <img src="./assets/tiya/line7.png" width="100%" class="active">
                <div class="Earth_Border7 Base_Border rippletshow"></div>
              </div>
            </div>
            <div class="clearfix">
              <a href="javascript:void(0)" class="Globalization_More Globalization_More1">
                <div class="CenterPor">
                  <h2>We come from<br>different corners<br>of the world</h2>
                  <p>Headquartered in Singapore, TIYA builds a global team with members from different
                    countries or regions. Voices connect us beyond cultural disparities and regions.
                  </p>
                  <span id="btnmore1"></span>
                </div>
              </a>
              <a href="javascript:void(0)" class="Globalization_More Globalization_More2">
                <div class="CenterPor">
                  <h2>We set out<br>in all directions</h2>
                  <p>TIYA’s products have been ranking among the top 10 social networking apps in 70
                    countries at peak time. TIYA’s audio-based social networking has redefined the
                    boundaries of social communication, since you will always be able to find “your
                    community” and your “tiny little world”. <br>We expect to further expand our
                    global market in the future, and bring customers from all corners of the world
                    together through voices.</p>
                  <span id="btnmore2"></span>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="News">
        <div class="container">
          <h2 class="BaseH" data-scroll-reveal="wait 0.1s, then enter bottom and move 40px over 1s"
            data-scroll-reveal-id="3"
            style="-webkit-transform: translatey(0);transform: translatey(0);opacity: 1;-webkit-transition: -webkit-transform 1s ease-in-out 0.1s,  opacity 1s ease-in-out 0.1s;transition: transform 1s ease-in-out 0.1s, opacity 1s ease-in-out 0.1s;-webkit-perspective: 1000;-webkit-backface-visibility: hidden;"
            data-scroll-reveal-initialized="true">NEWS</h2>
          <div class="clearfix">
            <a href="javascript:void 0" target="_self" class="News_Fx"
              data-scroll-reveal="wait 0.12s, then enter left and move 40px over 1s" data-scroll-reveal-id="4"
              style="-webkit-transform: translatex(0);transform: translatex(0);opacity: 1;-webkit-transition: -webkit-transform 1s ease-in-out 0.12s,  opacity 1s ease-in-out 0.12s;transition: transform 1s ease-in-out 0.12s, opacity 1s ease-in-out 0.12s;-webkit-perspective: 1000;-webkit-backface-visibility: hidden;"
              data-scroll-reveal-initialized="true">
              <div class="News_FxImg">
                <img src="./assets/tiya/d8cf24b4-caf7-4ddd-a9e3-5f8504bc2b3b.png">
              </div>
              <div class="News_FxColor">
                <div class="CenterPor">
                  <p>Jun 06, 2022</p>
                  <h2></h2>
                  <span></span>
                </div>
              </div>
            </a>
            <div class="News_R">
              <a class="News_Fx" data-scroll-reveal="wait 0.14s, then enter top and move 40px over 1s"
                href="javascript:void 0" target="_self" data-scroll-reveal-id="5"
                style="-webkit-transform: translatey(0);transform: translatey(0);opacity: 1;-webkit-transition: -webkit-transform 1s ease-in-out 0.14s,  opacity 1s ease-in-out 0.14s;transition: transform 1s ease-in-out 0.14s, opacity 1s ease-in-out 0.14s;-webkit-perspective: 1000;-webkit-backface-visibility: hidden;"
                data-scroll-reveal-initialized="true">
                <div class="News_FxImg">
                  <img src="./assets/tiya/15db0288-3444-4859-ba50-f63ffe34cfea.png">
                </div>
                <div class="News_FxColor">
                  <div class="CenterPor">
                    <p>May 09, 2022</p>
                    <h2></h2>
                    <span></span>
                  </div>
                </div>
              </a><a class="News_Fx" data-scroll-reveal="wait 0.14s, then enter top and move 40px over 1s"
                href="javascript:void 0" target="_self" data-scroll-reveal-id="6"
                style="-webkit-transform: translatey(-40px);transform: translatey(-40px);opacity: 0;-webkit-transition: -webkit-transform 1s ease-in-out 0s,  opacity 1s ease-in-out 0.14s;transition: transform 1s ease-in-out 0s,  opacity 1s ease-in-out 0.14s;-webkit-perspective: 1000;-webkit-backface-visibility: hidden;"
                data-scroll-reveal-initialized="true">
                <div class="News_FxImg">
                  <img src="./assets/tiya/df8716c9-6502-4356-8f4c-60ca357c0630.png">
                </div>
                <div class="News_FxColor">
                  <div class="CenterPor">
                    <p>May 05, 2022</p>
                    <h2></h2>
                    <span></span>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="Globalize" data-scroll-reveal="wait 0.1s, then enter right and move 40px over 1s"
          data-scroll-reveal-id="7"
          style="-webkit-transform: translatex(40px);transform: translatex(40px);opacity: 0;-webkit-transition: -webkit-transform 1s ease-in-out 0s,  opacity 1s ease-in-out 0.1s;transition: transform 1s ease-in-out 0s,  opacity 1s ease-in-out 0.1s;-webkit-perspective: 1000;-webkit-backface-visibility: hidden;"
          data-scroll-reveal-initialized="true"><img src="./assets/tiya/title.png" width="100%">
          <div class="Globalize_Center">Take Your Career to The Next Level
            <h2 onclick="window.location=&#39;http://jobs.tiya.com/&#39;"><i>Explore open positions</i></h2>
          </div>
        </div>
      </div>
    </div>
    <!-- Footer end -->
    <div class="go_top"></div>
  </div>
</template>

<style>
@import './assets/tiya/reset.css';
@import './assets/tiya/pc.css';
@import './assets/tiya/phone.css';
@import './assets/tiya/swiper-3.4.2.min.css';
</style>

<script>
import config from '../../configCenter.json'
export default {
  mounted() {
    document.title = config.companyFullName
    var timeControl1, timeControl2, lock;
    const $ = window.$

      ; (function (doc, win) {
        var docEl = doc.documentElement,
          resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',
          recalc = function () {
            var clientWidth = docEl.clientWidth;
            if (!clientWidth) return;
            if (clientWidth >= 750) {
              docEl.style.fontSize = '100px';
            } else {
              docEl.style.fontSize = 100 * (clientWidth / 750) + 'px';
            }
          };

        if (!doc.addEventListener) return;
        win.addEventListener(resizeEvt, recalc, false);
        doc.addEventListener('DOMContentLoaded', recalc, false);
      })(document, window);

    //返回顶部
    // $(".go_top").click(function() {
    // 	$("html,body").animate({
    // 		scrollTop: '0px'
    // 	});
    // });
    $(function () {
      $(".ArrowImg").click(function () {
        $("html, body").animate({
          scrollTop: $("#page").offset().top
        }, {
          duration: 500,
          easing: "swing"
        });
        return false;
      });
    });

    handleJs1();

    window.resize = function () {
      handleJs1();
    }

    function handleJs1() {
      if (window.innerWidth >= 1023) {

        var Height = document.documentElement.clientHeight;
        $(".OtherBanner,.OtherBanner img").height(Height);
      }
    }

    $(function () {
      handleJs1();
      if (window.innerWidth < 1023) {
        $(".menu-li ul li > a").click(function () {
          if ($(this).parent("li").hasClass("on")) {
            $(".menu-li ul li dl").stop().slideUp();
            $(".menu-li ul li").removeClass("on");
          } else {
            $(".menu-li ul li dl").stop().slideUp();
            $(this).parent("li").find("dl").stop().slideDown();
            $(".menu-li ul li").removeClass("on");
            $(this).parent("li").addClass("on");
          }
        });
        $('.menu-li dl dt .MenuMain').on('click', function () {
          $(this).toggleClass("Nor");
        });
        // 测导航
        $('.mobile-nav').on('click', function () {
          if (!$(this).hasClass("on")) {
            $(this).addClass("on");
            $(".menu-li").slideDown();
          } else {
            $(this).removeClass("on");
            $(".menu-li").slideUp();
          }
        });
        $(".am_subfooterdiv h2").click(function () {
          if ($(this).parent(".am_subfooterdiv").hasClass("on")) {
            $(".am_subtag").stop().hide();
            $(".am_subfooterdiv").removeClass("on");
          } else {
            $(".am_subtag").stop().hide();
            $(this).parent(".am_subfooterdiv").find(".am_subtag").stop().show();
            $(".am_subfooterdiv").removeClass("on");
            $(this).parent(".am_subfooterdiv").addClass("on");
          }
        });

      } else {
        $(window).scroll(function () {
          if ($(window).scrollTop() >= 50) {
            $(".HeaderFlix").addClass('on');
          } else {
            $(".HeaderFlix").removeClass('on');
          }
        });
        $(".Technology_Fx span").hover(function () {
          $(this).parents().addClass('active');
          $(this).css('transform', 'rotate(270deg)');
        });
        $(".Technology_Fx").hover(function () { }, function () {
          $(this).find('.Globalization_More').removeClass('active');
          $(this).find('span').css('transform', 'rotate(90deg)');
        });
      }
      $(".Talents_Ct .Talents_LayoutR .Talents_Fx").hover(function () {
        $(this).addClass('active').siblings().removeClass('active');
        var index = $(this).index();
        $(".Talents_Ct .Talents_Img").eq(index).addClass('active').siblings().removeClass('active');
      });
      $(".Development_Ct .Talents_LayoutR .Talents_Fx").hover(function () {
        $(this).addClass('active').siblings().removeClass('active');
        var index = $(this).index();
        $(".Development_Ct .Talents_Img").eq(index).addClass('active').siblings().removeClass('active');
      });

      window.scrollReveal = new window.scrollReveal({
        reset: true,
        move: '50px'
      });
    })


    $(function () {
      $(".HeaderFlix").addClass('Nor');
      $(".CenterPor #btnmore1").click(function () {
        //$(".Globalization_More2").css("opacity", "1");
        $(".Globalization_More1").removeClass("active").addClass("qiehuan");
        $(".Globalization_More2").addClass("active").removeClass("qiehuan");
        setTimeout(function () {
          $(".Globalization_More1").css("opacity", "0");
        }, 2000)
        setTimeout(function () {
          $(".Globalization_More2").css("opacity", "1");
        }, 4000)
      })
      $(".CenterPor #btnmore2").click(function () {
        $(".Globalization_More2").removeClass("active").addClass("qiehuan");
        $(".Globalization_More1").addClass("active").removeClass("qiehuan");
        setTimeout(function () {
          $(".Globalization_More2").css("opacity", "0");
        }, 2000)
        setTimeout(function () {
          $(".Globalization_More1").css("opacity", "1");
        }, 4000)
      })
      $(window).scroll(function () {
        var earthTOp = $(".Earth").offset().top;
        // var more = $(".Globalization_More1").offset().top
        var scrollTop = $(this).scrollTop();

        // if (scrollTop >= earthTOp - 220) {
        // 	$(".Globalization_More1").addClass('active');
        // 	setTimeout(function() {
        // 		$(".Globalization_More2").addClass('active');
        // 	}, 2000)
        // } else {
        // 	$(".Globalization_More").removeClass('active');
        // }

        if (scrollTop >= earthTOp + 400 || scrollTop < earthTOp - 1300) {
          if (!lock) {
            return;
          }
          lock = false
          $(".Earth_Dian").removeClass("active");
          $(".Earth_Ct img").removeClass("active");
          $(".Base_Border").removeClass("rippletshow");
          $(".Earth_Border2,.Earth_Border3,.Earth_Border4,.Earth_Border5,.Earth_Border6,.Earth_Border7")
            .removeClass("active");
          $(".Earth_Bordert,.Earth_Border1").removeClass("active");
          timeControl1 && clearTimeout(timeControl1)
          timeControl2 && clearTimeout(timeControl2)
        } else if (scrollTop >= earthTOp - 500) {
          if (lock) {
            return
          }
          lock = true
          timeControl1 && clearTimeout(timeControl1)
          timeControl2 && clearTimeout(timeControl2)
          $(".Earth_Dian").addClass("active");
          $(".Earth_Ct img").addClass("active");
          $(".Base_Border").addClass("rippletshow");
          timeControl1 = setTimeout(function () {
            $(".Earth_Border2,.Earth_Border3,.Earth_Border4,.Earth_Border5,.Earth_Border6,.Earth_Border7")
              .addClass("active");
            timeControl2 = setTimeout(function () {
              $(".Earth_Bordert,.Earth_Border1").addClass("active");
            }, 680)
          }, 3200)
        }
      })
    })

    // banner轮播
    window.onload = function () {
      handleJs();
    };

    function handleJs() {
      if (window.innerWidth >= 1023) {
        var Height = document.documentElement.clientHeight;
        $(".IndexBanner .swiper-slide img,.IndexBanner,.IndexBanner .swiper-slide").height(Height);
      }

      new window.Swiper('.IndexBanner .swiper-container', {
        autoplay: false,
        speed: 1000,
        loop: true,
        observer: true,
        observeParents: true,
        pagination: '.swiper-pagination',
        paginationClickable: true
      });
    }
  }
}
</script>

<style>
.wrapper {
  padding-top: 0!important;
}
</style>